<template>
  <div>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${itemDelete.name_comercial}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { n: itemDelete.name_comercial }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDialogDelete()"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('agente:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.name') }}
            </th>
            <!--<th
              width="40%"
              class="text-uppercase"
            >
              {{ $t('lbl.description') }}
            </th>-->
            <th class="text-uppercase">
              {{ $t('lbl.contacto') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.active') }}
            </th>
            <th
              width="5%"
              class="text-uppercase text-center"
            >
              {{ $t('lbl.code') }}
            </th>
            <th
              v-if="permisos.includes('agente:edit') || permisos.includes('agente:destroy') || permisos.includes('*')"
              class="text-uppercase"
              width="10%"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              {{ iten.name_comercial }}
            </td>
            <!--<td>
              {{ iten.description }}
            </td>-->
            <td>
              {{ iten.name_contact }}<br />
              {{ iten.email_contact }}
            </td>
            <td>
              <v-switch
                v-model="iten.active"
                :disabled="!permisos.includes('agente:edit') && !permisos.includes('*')"
                @change="editActive(iten)"
              ></v-switch>
            </td>
            <td>{{ iten.code_unique }}</td>
            <td
              v-if="permisos.includes('agente:edit') || permisos.includes('agente:destroy') || permisos.includes('*')"
            >
              <v-tooltip
                v-if="permisos.includes('agente:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="permisos.includes('agente:destroy') || permisos.includes('*')"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'

export default {
  data() {
    return {
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
    }
  },
  mounted() {
    this.load()
  },
  created() {
    this.fetchDataFromEndpoints()
  },
  methods: {
    ...mapMutations([
      'setListUser',
      'setSearchUsers',
      'setTotalUsersFilters',
      'setTotalUsersAll',
      'setUserItemsPerPage',
      'setUserOnlyShow',
    ]),
    async fetchDataFromEndpoints() {
      try {
        const [
          resUsers,
        ] = await Promise.all([
          this.axios.post('users/list'),
        ])

        // USERS
        this.setListUser(resUsers.data)
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    async load() {
      await this.axios
        .get(
          `agentes?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${
            this.search
          }&perfil_slug=${localStorage.getItem('perfil')}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page
          }
        })
    },
    onPageChange() {
      this.load()
    },
    showModal(item) {
      this.item = item
      this.isLoad = true
      if (item.permisos !== null) {
        this.abilities = item.permisos.abilities
      } else {
        this.abilities = []
      }
      this.loadPermisos()
      this.isDialogVisible = !this.isDialogVisible
    },
    async loadPermisos() {
      this.abilityList = []
      await this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const items = res.data.data
          // eslint-disable-next-line no-plusplus
          for (let indexA = 0; indexA < this.abilities.length; indexA++) {
            const ability = this.abilities[indexA]
            if (ability === '*') {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                this.abilityList.push(element.name)
              }
            } else {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                if (element.ability === ability) {
                  this.abilityList.push(element.name)
                }
              }
            }
          }
          // eslint-disable-next-line no-return-assign
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoad = false))
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    closeDialogDelete() {
      this.isDialogVisibleDelete = !this.isDialogVisibleDelete
      this.itemDelete = {}
    },
    newItem() {
      localStorage.removeItem('agente-id')
      this.$router.push({ name: 'agentes-update' })
    },
    editItem(iten) {
      localStorage.setItem('agente-id', iten.id)
      this.$router.push({ name: 'agentes-update' })
    },
    async editActive(iten) {
      await this.axios
        .post(
          `agentes/active/${iten.id}`,
          { active: iten.active },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          }
        })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.itemDelete = iten
    },
    async confirmDelete() {
      await this.axios
        .delete(`agentes/${this.itemDelete.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.closeDialogDelete())
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
